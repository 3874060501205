@font-face {
  font-family: 'OpenSans-Regular';
  src: local('OpenSans-Regular'),
    url('./../../assets/fonts/open-sans/OpenSans-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: local('OpenSans-Bold'),
    url('./../../assets/fonts/open-sans/OpenSans-Bold.ttf') format('woff');
}

@font-face {
  font-family: 'OpenSans-SemiBold';
  src: local('OpenSans-SemiBold'),
    url('./../../assets/fonts/open-sans/OpenSans-SemiBold.ttf') format('woff');
}

@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: local('OpenSans-ExtraBold'),
    url('./../../assets/fonts/open-sans/OpenSans-ExtraBold.ttf') format('woff');
}
